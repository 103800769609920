import React, { useContext, useEffect, useState} from 'react';
import MUIDataTable from "mui-datatables";
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { useLocation } from "react-router-dom";
import { createTheme } from "@material-ui/core/styles";
import { MuiThemeProvider } from "@material-ui/core/styles";
// import moment from "moment"
import moment from 'moment-timezone';
//Context 
import { AuthContext } from '../../context/AuthContext';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { getDetailsHandle } from "../../pages/feedback/Feedback"
const TicketListTable = (props) => {
  const location = useLocation();
    const [rowId, setRowId] = useState("");
    const [rowMetaData, setRowMetaData] = useState("");

     const [defaultTableText, setDefaultTableText] = useState(props.progress)
    const [snackBarMessage, setsnackBarMessage] = useState(false);
     const [open, setOpen] = React.useState(false);
    const history = useHistory();


    useEffect(() => {
      setDefaultTableText(props.progress)
    }, [props.progress])
  
    const handleRowClick = (rowData, rowMeta) => {
        // console.error("row data is ");
        console.error(props.data[rowMeta.dataIndex]);
        console.log(props.data[rowMeta.dataIndex]._id)
        setRowMetaData(rowMeta);
        setRowId(props.data[rowMeta.dataIndex]._id)
       // getDetailsHandle(props.data[rowMeta.dataIndex]._id, rowData);
    //    history.push({
    //     pathname: '/feedback-form',
    //     search: '?query=abc',
    //     state: {rowId :  rowId}
    // })
    props.refreshTicketList();
    history.push({
      pathname: '/feedback-form',
      search: props.data[rowMeta.dataIndex]._id,
      state: props.data[rowMeta.dataIndex]._id
    })
        // history.push('/feedback-form');
        // getDetailsHandle(data[rowMeta.dataIndex]._id, rowData);
        // setShowUpdateLeadModal(true);
    
        // data[rowMeta.dataIndex]._id, rowData
    
        // setRowMetaData(rowMeta);
        // setRowId(props.data[rowMeta.dataIndex]._id)
        // console.log("showupdate modal", showUpdateLeadModal);
      };
            useEffect(() => {
        setsnackBarMessage("Fetching Records...!");
        setOpen(true)
  
      }, []);
//  export const getDetailsHandle = (rowMetaData, rowId) => {
//    return(
//     rowId={rowId},
//      rowMetaData={rowMetaData}
//    )
//  }
// const rowdata = getDetailsHandle(rowId, rowMetaData)

const handleClick = () => {
  setOpen(true);
};

const handleClose = (event, reason) => {
  if (reason === 'clickaway') {
    return;
  }
  setOpen(false);
};

    const columns = [
        {
         name: "_id",
         label: "Id",
         options: {
          filter: true,
          sort: true,
          display:false,
          setCellHeaderProps: () => ({
            style: {
              whiteSpace: "nowrap",
              background: "#e6e6e6",
              fontWeight: 'bold'
            }
          })
         }
        },
        {
          name: "assigned_to",
          label: "Techie Name",
          options: {
            filter: true,
            sort: true,
            display: true,
            setCellHeaderProps: () => ({
              style: {
                whiteSpace: "nowrap",
                background: "#e6e6e6",
                fontWeight: 'bold'
              }
            }),
            customBodyRender: (value) => (
              <div>{value ==="undefined undefined" ? 'Still not assigned' : value}</div>
            )
          }
        },
        {
         name: "company_name",
         label: "Account Name",
         options: {
          filter: true,
          sort: true,
          setCellProps: () => ({
            style: {
              whiteSpace: "nowrap",
              position: "sticky",
              left: "0",
              background: "white",
              zIndex: 100,
              color: '#243d6a',
              fontWeight: 'bold'
            }
          }),
          setCellHeaderProps: () => ({
            style: {
              whiteSpace: "nowrap",
              position: "sticky",
              left: 1,
              background: "#e6e6e6",
              zIndex: 101,
              textColor: '#FFFFFF'
            }
          })
         }
        },
    
        {
            name: "family",
            label: "Product Family",
            options: {
             filter: true,
             sort: true,
             setCellHeaderProps: () => ({
                style: {
                  whiteSpace: "nowrap",
                  background: "#e6e6e6",
                  fontWeight: 'bold'
                }
              })
            }
           },
           {
            name: "product",
            label: "Application",
            options: {
             filter: true,
             sort: true,
             setCellHeaderProps: () => ({
                style: {
                  whiteSpace: "nowrap",
                  background: "#e6e6e6",
                  fontWeight: 'bold'
                }
              })
            }
           },
           {
            name: "area",
            label: "Area",
            options: {
             filter: true,
             sort: true,
             setCellHeaderProps: () => ({
                style: {
                  whiteSpace: "nowrap",
                  background: "#e6e6e6",
                  fontWeight: 'bold'
                }
              })
            }
           },
           {
            name: "sub_area",
            label: "SubArea",
            options: {
             filter: true,
             sort: true,
             setCellHeaderProps: () => ({
                style: {
                  whiteSpace: "nowrap",
                  background: "#e6e6e6",
                  fontWeight: 'bold'
                }
              })
            }
           },
           {
            name: "product_version",
            label: "Product Version",
            options: {
             filter: true,
             sort: true,
             setCellHeaderProps: () => ({
                style: {
                  whiteSpace: "nowrap",
                  background: "#e6e6e6",
                  fontWeight: 'bold'
                }
              })
            }
           },
           {
            name: "platform",
            label: "Platform",
            options: {
             filter: true,
             sort: true,
             setCellHeaderProps: () => ({
                style: {
                  whiteSpace: "nowrap",
                  background: "#e6e6e6",
                  fontWeight: 'bold'
                }
              })
            }
           },
           {
            name: "operating_system",
            label: "OS",
            options: {
             filter: true,
             sort: true,
             setCellHeaderProps: () => ({
                style: { 
                  whiteSpace: "nowrap",
                  background: "#e6e6e6",
                  fontWeight: 'bold'
                }
              })
            }
           },
           {
            name: "summary",
            label: "Summary",
            options: {
             filter: true,
             sort: true,
             setCellHeaderProps: () => ({
                style: {
                  whiteSpace: "nowrap",
                  background: "#e6e6e6",
                  fontWeight: 'bold'
                }
              }),
              // setCellProps: () => ({
              //   style: {
              //     whiteSpace: "nowrap",
              //     // background: "#e6e6e6",
              //     width: '500px',
              //     maxWidth: '500px',
              //     overflow: 'auto',
              //     // color: '#243d6a',
              //     // fontWeight: 'bold'
              //   }
              // }),
            }
           },
           {
            name: "description",
            label: "Description",
            options: {
             filter: true,
             sort: true,
             setCellHeaderProps: () => ({
              style: {
                whiteSpace: "nowrap",
                background: "#e6e6e6",
                width: '500px',
                maxWidth: '500px',
                overflow: 'auto'
              }
              }),
              // setCellProps: () => ({
              //   style: {
              //     whiteSpace: "nowrap",
              //     // background: "#e6e6e6",
              //     width: '500px',
              //     maxWidth: '500px',
              //     overflow: 'auto',
              //     // color: '#243d6a',
              //     // fontWeight: 'bold'
              //   }
              // }),
            }
           },
           {
            name: "status",
            label: "Status",
            options: {
             filter: true,
             sort: true,
             setCellHeaderProps: () => ({
                style: {
                  whiteSpace: "nowrap",
                  background: "#e6e6e6",
                  fontWeight: 'bold'
                }
              })
            }
           },
           {
            name: "opened_on",
            label: "Received Date",
            options: {
              display: true,
              customBodyRender: (value, tableMeta, updateValue) => (
                value ? moment(value.replace("T", " ").replace("Z", " ")).tz("Asia/Kolkata").format('D MMM  YYYY') : ""
              ),
              filter: true,
              setCellHeaderProps: () => ({
                style: {
                  whiteSpace: "nowrap",
                  background: "#e6e6e6",
                  fontWeight: 'bold'
                }
              })
            }
          },
          {
            name: "closed_on",
            label: "Closed Date",
            options: {
              display: true,
              customBodyRender: (value, tableMeta, updateValue) => (
                value ? moment(value.replace("T", " ").replace("Z", " ")).tz("Asia/Kolkata").format('D MMM  YYYY') : ""
              ),
              filter: true,
              setCellHeaderProps: () => ({
                style: {
                  whiteSpace: "nowrap",
                  background: "#e6e6e6",
                  fontWeight: 'bold'
                }
              })
            }
          },
       ];
       
    
    const options = {
         textLabels: {
          body: {
            noMatch: defaultTableText
          }
        },
        filter: true,
        filterType: "dropdown",
         responsive: "standard",
         tableBodyHeight: (window.innerHeight - 180).toString() + 'px',
         tableBodyMaxHeight: (window.innerHeight - 180).toString() + 'px',
         fixedHeader: true,
         fixedSelectColumn: false,
         selectableRows: false,
         onRowClick: handleRowClick,
         download: false,
         print:  false,
      };

      // console.log("table length is" + props.length)
       const getMuiTheme = () =>
  createTheme({
    overrides: {
      MUIDataTable: {
        root: {
          backgroundColor: "#FF000"
        },
        paper: {
          boxShadow: "none"
        },
      },
      MUIDataTableBodyCell: {
        root: {
          whiteSpace: "nowrap",
          textColor: '#FFFFFF',
          color: '#243d6a',
          fontWeight: 'bold'
        }
      },
    }
  });

    return(
        <>
        <MuiThemeProvider theme={getMuiTheme()}>
            <MUIDataTable
          // styles={{ styles }}
          title={"Tickets List"}
          data={props.data}
          columns={columns}
          options={options}
          // rowId={rowId} 
          // metaData={rowMetaData}
        />
           </MuiThemeProvider>
           <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={open}
        autoHideDuration={2000}
        onClose={handleClose}
        message={snackBarMessage}
        action={
          <React.Fragment>
            <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
        </>
    )
}
export default TicketListTable;