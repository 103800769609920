import React, { Component, useContext, useEffect } from 'react';
import Home from "./pages/home/Home";
import TicketsList from "./pages/tickets/TicketsList";
import Feedback from "./pages/feedback/Feedback";
// import Register  from './pages/Auth/register/Register';
import  ForgotPassword  from './pages/Auth/forgotPassword/ForgotPassword';
import ResetPassword  from './pages/Auth/resetPassword/ResetPassword';
import Profile from './pages/Auth/profile/Profile';
//  import Profile from "./pages/Auth/profile";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router, Route, Switch, Redirect} from "react-router-dom";
import Login from './pages/Auth/login/Login';
import { AuthContext } from './context/AuthContext';
import axios from "axios";

//beta API
//  axios.defaults.baseURL = 'https://crm.beta.kaizenat.com/api'; // this is beta api .

// live API
 axios.defaults.baseURL = 'https://crm.kaizenat.com/api'; // this is live api database.  

function App() {
 const { user } = useContext(AuthContext);

 axios.defaults.headers.common['Authorization'] = user ? user.token : null;

  useEffect(() => {

    if (user) {

      // Initiating idleTime 
      var idleInterval = 0;

      // on mouse move setting idletime to 0
      document.addEventListener('mousemove', (e) => {
        // console.log("mouse move", idleInterval);
        idleInterval = 0;
        // console.log("mouse move", idleInterval)
      });

      // on key press setting idle time to 0 
      window.addEventListener('keypress', e => {
        console.log("key press", idleInterval);
        idleInterval = 0;
        console.log("key press", idleInterval)
      });

      // defining set interval if idletime > 10 minutes logout.
      const interval = setInterval(() => {

        idleInterval += 1;
        // console.log("every interval idletime is ", idleInterval);

        if (idleInterval >= 5) {
          // console.log('This will run every second!', idleInterval);
          window.localStorage.clear();
          window.location.reload();
        }

      }, 60000);
      // return () => clearInterval(interval);

    }

  }, []);

  return (
   <>
   <Router>
     <Switch>
       <Route exact path="/">
          {user ? <Home /> : <Login />}
        </Route>


        <Route path="/login">
          {user ? <Redirect to="/" /> : <Login />}
        </Route>
        {/* <Route path="/register">
          {user ? <Redirect to="/" /> : <Register />}
        </Route> */}
        <Route path="/forgot-password">
          {user ? <Redirect to="/" /> : <ForgotPassword />}
        </Route>
        <Route path="/reset-password">
          {user ? <Redirect to="/" /> : <ResetPassword />}
        </Route>

        <Route exact path="/ticket-list">
          {user ? <TicketsList /> : <Login />}
        </Route>

        <Route path="/feedback-form">
          {user ? <Feedback></Feedback> : <Login />}
        </Route>
        <Route path="/profile">
          {user ? <Profile></Profile> : <Login />}
        </Route>
        {/* <Route exact path='/' component={Home} />
        <Route exact path='/ticket-list' component={TicketsList} />
        <Route exact path='/feedback-form' component={Feedback} /> */}
     </Switch>
   </Router>

   </>
  );
}

export default App;
