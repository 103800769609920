import React, { useState, useContext, useEffect, useRef } from 'react';
import { Modal } from 'react-bootstrap'
import axios from "axios";
import { AuthContext } from '../../context/AuthContext';
import { Link, Redirect, useHistory } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';

// Snackbar notification
import ButtonMaterialUi from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

// React hook use form
import { useForm } from "react-hook-form";
import Select from 'react-select';
import { disable } from 'plotly.js/dist/plotly-cartesian';

import { EditorState, convertToRaw, ContentState} from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const ActivityTicket = (props) => {
    //      const { user } = useContext(AuthContext);

    // snakbar
    const [snackBarMessage, setSnackBarMessage] = useState("");
    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);

    // Add Contact params 
    const [ContactsShow, setContactsShow] = useState(false);
    const [applicationList, setApplicationList] = useState([]);
    const [familyList, setFamilyList] = useState([]);
    const [productList, setProductList] = useState([]);
    const [osList, setOsList] = useState([]);
    const [versionList, setVersionList] = useState([]);
    const [areaList, setAreaList] = useState([]);
    const [subAreaList, setSubAreaList] = useState([]);
    const [companiesList, setCompaniesList] = useState();
    const [selectedAccount, setSelecteAccount] = useState("");
    const [disable, setDisable] = useState(false);
    const [titleList, setTitleList] = useState([]);
    const [activity, setActivity] = useState({
        account_id: props.feedback ? props.feedback.account_key : null,
        ticket_id: props.feedback ? props.feedback.ticket_id : null,
        summary: '',
        description: ''
    })

    const html = `<p>
    <br /><br /><br /><br /><br />
    </p>`;
    // const html = `<p>Add Activity...
    // <br /><br /><br /><br /><br />
    // </p>`;
    const contentBlock = htmlToDraft(html);
    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
    const editorStateTe = EditorState.createWithContent(contentState);
    const [editorState, setEditorState] = useState(editorStateTe)
    const mailBody = useRef();

    const { register, handleSubmit, formState: { errors: errors }, setError: setActivityError, reset: reset } = useForm();

    const getTitleList = () => {
        setTitleList([{ label: "My Reply", value: 'My Reply' }, { label: "Manager Suggestion", value: 'Manager Suggestion' }, { label: "Tagged", value: 'Tagged' }])
    }
    useEffect(() => {
        getTitleList();
    }, [])

    const handleClose = (event, reason) => {
        setAnchorEl(false);
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };
    
    const onEditorStateChange = (data) => {
        console.log("editor data", data)
        setEditorState(data)
    }

    const addActivityTicket = async (e) => {
        setDisable(true);
        // e.preventDefault();
        setSnackBarMessage("Uploading Data ...!");
        setOpen(true);

        setTimeout(() => { }, 500);

        const activityData = {
            // first one will schema feilds

            // account_id:selectedAccount,
            account_id: activity.account_id,
            ticket_id: activity.ticket_id,
            summary: activity.summary,
            // description: activity.description,
            description : mailBody.current.value.replace(/<\/?[^>]+(>|$)/g, '').replace(/;/g, '').replace(/&nbsp/g, '')
        }

        try {
            const res = await axios.post("/tickets/add-activity", activityData);
            if (res.data.status === "success") {

                setTimeout(() => {
                    setOpen(true);
                    setSnackBarMessage("Activity Ticket Successfully Added...!");
                    props.onHide();

                }, 2000);

                setSnackBarMessage("Activity Ticket Successfully Added...!");
                setContactsShow(false);


                props.refreshFeedbackList(activity.ticket_id);
            } else {
                setSnackBarMessage("Error in adding ticket...!");
                setContactsShow(true);
            }
            reset({
                keepErrors: false,
                keepDirty: true,
                keepIsSubmitted: false,
                keepTouched: false,
                keepIsValid: false,
                keepSubmitCount: false,
            })
            // props.onHide();
            setDisable(true);
        } catch (err) {
            setDisable(false);

            // if (err.response.status === 400) {

                // setActivityError("co_" + err.response.data.key, {
                //     type: "manual",
                //     message: err.response.data.message,
                // });                 
            // }

        }
    }

    const onInputChange = (e) => {
        setActivity({ ...activity, [e.target.name]: e.target.value }, []);
    }

    const colourStyles = {
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            // const color = chroma(data.color);

            return {
                ...styles,
                // background-color: transparent;
                backgroundColor: isFocused ? "#000" : '#000080',
                color: "white",
                opacity: '1.5'
            };
        }
    };

    const getAccountsList = async (value) => {

        setCompaniesList([]);
        const res = await axios.get("/tickets/accounts/search/" + value.target.value);
        setCompaniesList(res.data.accountsList);

        // setExistingCompany(false);

    }

    const handleSetCompanyName = (value) => {
        setSelecteAccount(value.value)
        // setCompaniesList({ ...companiesList, [e.target.name]: e.target.value }, []);
    }
    return (
        <>
            {/* Add Contacts Modal */}
            < Modal
                show={props.show}
                onHide={() => setContactsShow(false)}
                backdrop={false}
                disableBackdrop={true}
                dialogClassName="modal-90w"
                arialabelledby="example-custom-modal-styling-title">
                <Modal.Header >
                    <Modal.Title id="example-custom-modal-styling-title">
                        Activity Ticket
                    </Modal.Title>
                    <a onClick={props.onHide} className="btn btn-sm btn-white btn-danger bottomBarButtons">Cancel</a>
                </Modal.Header>
                {/* onSubmit={handleSubmit(addActivityHandle)} */}
                <Modal.Body>
                    <div className="from">
                        <form key={1} onSubmit={handleSubmit(addActivityTicket)} >
                            {/* <div className="row">
                            <div className="col-md-6">
                            <Select
                        closeMenuOnSelect={true}
                        className="basic-single"
                        classNamePrefix="select"
                        isSearchable="true"
                        options={companiesList}
                        placeholder="Account"
                        styles={colourStyles}                       
                        onChange={handleSetCompanyName}
                        onKeyDown={getAccountsList}
                        // onNewOptionClick={onNewOptionClick}
                      ></Select>
                            
                            </div>
                            </div>
                            <br /> */}
                            <div className="row">
                                <div className="col-md-6">

                                    <TextField variant="outlined"
                                        {...register("summary", {
                                        })}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        value={activity.summary}
                                        select
                                        helperText={errors.summary ? errors.summary.message : ''}
                                        error={Boolean(errors.summary)}
                                        onChange={e => onInputChange(e)}
                                        required
                                        type="text" className="form-control" name="summary" label="Title"
                                    >
                                        {titleList.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))
                                        }
                                    </TextField>

                                    {/* <TextField variant="outlined" autoComplete="none"
                                    {...register("summary", {
                                        //     required: { 
                                        //         value: true, 
                                        //         message: "Designation is required" 
                                        //     },
                                        minLength: {
                                            value: 10,
                                            message: "summary must be at least 10 characters"
                                        }
                                    })}
                                    required
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    // style={{
                                    //     borderLeftWidth: 3,
                                    //     borderLeftColor: 'red',
                                    //     borderLeftStyle: 'solid'
                                    // }}
                                    onChange={e => onInputChange(e)}
                                    value={activity.summary}
                                    helperText={errors.summary ? errors.summary.message : ''}
                                    error={Boolean(errors.summary)}
                                    // inputRef={designation}
                                    type="text" className="form-control" id="summary" name="summary" label="Summary" /> */}
                                </div>
                            </div>
                            <br />

                            <div className='row'>
                                {/* <div className="col-md-6">
                                    <TextField multiline rows={2}
                                        variant="outlined"
                                        {...register("description", {
                                            //     required: { 
                                            //         value: true, 
                                            //         message: "Designation is required" 
                                            //     },
                                            minLength: {
                                                value: 15,
                                                message: "description must be at least 15 characters"
                                            }
                                        })}
                                        required
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        onChange={e => onInputChange(e)}
                                        value={activity.description}
                                        helperText={errors.description ? errors.description.message : ''}
                                        error={Boolean(errors.description)}
                                        // inputRef={designation}
                                        type="text" className="form-control" id="description" name="description" label="Description" /> */
                                    // </div>

                                    <div className="form-group col-md-12 editor" style={{ marginTop: '30px' }} >
                                        <label className="font-weight-bold"> Mail Body <span className="required"> * </span> </label>
                                        <div style={{ minHeight: '350px', border: '1px grey solid' }}>

                                            <Editor
                                                editorState={editorState}
                                                toolbarClassName="toolbarClassName"
                                                wrapperClassName="wrapperClassName"
                                                editorClassName="editorClassName"
                                                // onEditorStateChange={onEditorStateChange}
                                                onEditorStateChange={onEditorStateChange}
                                            />
                                        </div>
                                        <textarea
                                            style={{ display: 'none' }}
                                            disabled
                                            ref={mailBody}
                                            value={draftToHtml(convertToRaw(editorState.getCurrentContent()))}
                                        />
                                        {/* <textarea style={{display:'none'}} disabled ref={(val) => userInfo.description = val} value={draftToHtml(convertToRaw(editorState.getCurrentContent())) } /> */}
                                    </div>
                                }
                            </div>
                            <br/>
                            <div className="row">
                                <div >
                                    <button disabled={disable} className="btn btn-sm btn-primary btn_submit_common_green  bottomBarButtons" id="submit"  >Submit</button>&nbsp;&nbsp;
                                </div>
                            </div>
                        </form>
                    </div>
                </Modal.Body>
            </Modal >
            {/* Snack Bar */}
            <Snackbar
                severity="success"

                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={open}
                autoHideDuration={2000}
                onClose={handleClose}
                message={snackBarMessage}
                action={
                    <React.Fragment>
                        <ButtonMaterialUi severity="success" color="success" size="small" onClick={handleClose}>
                        </ButtonMaterialUi>
                        <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    </React.Fragment>
                }
            />
        </>
    );
}
export default ActivityTicket;