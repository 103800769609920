import React,{useState, useEffect, useContext} from 'react';
import Navbar from '../home/Navbar';
import axios from "axios"
//import ticket list table here
import TicketListTable from "../../components/tables/TicketListTable";
//Context 
import { AuthContext } from '../../context/AuthContext';

const TicketsList = (props) => {

    const [data, setData] = useState([]);
    const { user } = useContext(AuthContext);
    const [loaded, setLoaded] = useState(false);
    const [progress, setProgress] = useState("Loading...");
     const userData = user.client;
    // const [data, setData] = useState([]);
const fechData = async () => {
    try{
    const res = await axios.get("/tickets/list")


    if (res.data.status === "success") {
      setProgress("No Records Found.")
    } else {
      setProgress("No Records Found.")
    }

    if(res.data.status === "success"){
    setData(res.data.ticketsList);
    setLoaded(true);
    }
      } catch (err) {
        setProgress("Error in Retrieving Data.")

        setTimeout(() => {
          // setOpen(false);
        }, 1000);
    }
}
 
useEffect(() => {
fechData();
},[])

    return(
        <>
        <Navbar value="tickets" className="activity_list_navbar" refreshTicketList={() => fechData()} />
        
         <TicketListTable data={data} progress={progress}  refreshTicketList={() => fechData()} /> 
        
        
        </>
    )
}
export default TicketsList;