import React, { useState, useContext} from 'react';
import './home.css';
import { Button } from 'react-bootstrap'
import { AuthContext } from '../../context/AuthContext';
import { Link, useHistory } from 'react-router-dom';
import RaiseTicket from "../../components/models/RaiseTicket";
import userImg from "../../images/user2.jpeg"
// import MUITextField from '@material-ui/TextField';

// Material Items
// import AddIcon from '@material-ui/icons/Add';

// import HomeIcon from '@material-ui/icons/Home';

// Importing pages modals from component
// import AddNewContact from "../../components/pageModals/AddNewContact";
// import AddNewLead from "../../components/pageModals/AddNewLead";

export default function Navbar({value,refreshTicketList}) {
  const { user } = useContext(AuthContext);

  const userData = user.client;
  const urlPath = value;

  const [showRaiseTicketModel, setShowRaiseTicketModell] = useState(false);
// console.log("props is", refreshTicketList)
    // const { user } = useContext(AuthContext);

    // const userData = user.user;

    // const permissions = user.permissions;

    // const [showAddContactsModal, setShowAddContactsModal] = useState(false);
    // const [showAddLeadModal, setShowAddLeadModal] = useState(false);

    // console.log(process.env.REACT_APP_PUBLIC_FOLDER);

    
    // const handleClose = (event, reason) => {
    //     setAnchorEl(false);
    //     console.log("handle close");
    //     if (reason === 'clickaway') {
    //         return;
    //     }
    //     setOpen(false);
    // };

    const history = useHistory();

    const [anchorEl, setAnchorEl] = React.useState(null);

    // const renderSwitch = (urlPath) => {
    //     console.log("url swithc", urlPath);
    //     switch (urlPath) {
    //         // case 'customers':
    //         //     return (userData.user_role_id.role_name === "user") ? <span style={{ marginLeft: '80.36px' }}></span> : false
    //         // return <span style={{ marginLeft: '80.36px' }}></span>;
    //         // return <Button  className="btn-sm navbarAddBtn"  onClick={() => setCustomersShow(true)}><AddIcon /> Add</Button>

    //         case 'contacts':
    //             // return permissions.includes("createOwnContacts") ? <Button className="btn-sm navbarAddBtn btn_add_common" onClick={() => setContactsShow(true)}><AddIcon /> Add</Button> : false
    //             return permissions.includes("createOwnContacts") ? 
    //                 <>
    //                     <Button className="btn-sm navbarAddBtn btn_add_common" onClick={() => setShowAddContactsModal(true)} >
    //                         <AddIcon /> Add
    //                     </Button>
    //                     <AddNewContact show={showAddContactsModal} onHide={() => setShowAddContactsModal(false)} />
    //                 </>
    //                 : false

    //         // case 'opportunity':
    //         //     return (userData.user_role_id.role_name === "user") ? <span style={{ marginLeft: '80.36px' }}></span> : false
    //         // return <Button className="btn-sm navbarAddBtn"  onClick={() => setOpportunityShow(true)}><AddIcon /> Add</Button>


    //         // case 'accounts':
    //         //     return (userData.user_role_id.role_name === "user") ? <span style={{ marginLeft: '80.36px' }}></span> : false
    //         // return <Button className="btn-sm navbarAddBtn"  onClick={() => setAccountShow(true)}><AddIcon /> Add</Button>


    //         case 'leads':
    //             // setLeadsShow 
    //             return permissions.includes("createOwnLeads") ? 
    //             <>
    //             <Button className="btn-sm navbarAddBtn btn_add_common" onClick={() => setShowAddLeadModal(true)} >
    //                 <AddIcon /> Add
    //             </Button>
    //             <AddNewLead show={showAddLeadModal} onHide={() => setShowAddLeadModal(false)} />
    //         </>
    //         : false


    //         case 'tickets':
    //             // setLeadsShow 
    //             return permissions.includes("createOwnLeads") ? 
    //             // <Button className="btn-sm navbarAddBtn btn_add_common" onClick={() => setDailyActivityShow(true)}><AddIcon /> Add</Button> : false
    //             <>
    //             <Button className="btn-sm navbarAddBtn btn_add_common" onClick={() => setShowAddLeadModal(true)} >
    //                 <AddIcon /> Raise Ticket
    //             </Button>
    //             <AddNewLead show={showAddLeadModal} onHide={() => setShowAddLeadModal(false)} />
    //         </>
    //         : false
            
    //             // case 'daily_activity':
    //         //     // addAnotherPerson = false;
    //         //     console.log("add another person inside switch", addAnotherPerson);
    //         //     return <Button className="btn-sm navbarAddBtn" onClick={() => setDailyActivityShow(true)}> <AddIcon /> Add</Button>
    //         default:
    //             // addAnotherPerson = false;
    //             // console.log("add another person switch case", addAnotherPerson);
    //             // console.log("url path in home page", urlPath);
    //             // return (permissions.includes("createOwnLeads") && urlPath === "home") ? <Button className="btn-sm navbarAddBtn" onClick={() => setDailyActivityShow(true)}> <AddIcon /> Add</Button> : <span style={{ marginLeft: '80px' }}>&nbsp;</span>
                
                
    //             // uncomment for adding leads 
    //             // return (permissions.includes("createOwnLeads") && urlPath === "home") ?
    //             return (permissions.includes("createOwnLeads") && urlPath === "home") ? 
    //             // <Button className="btn-sm navbarAddBtn btn_add_common" onClick={() => setDailyActivityShow(true)}><AddIcon /> Add</Button> : false
    //             <>
    //             <Button className="btn-sm navbarAddBtn btn_add_common" onClick={() => setShowAddLeadModal(true)} >
    //                 <AddIcon /> Add
    //             </Button>
    //             <AddNewLead show={showAddLeadModal} onHide={() => setShowAddLeadModal(false)} />
    //         </>
    //         : false
    //             //  <Button className="btn-sm btn_add_common" onClick={() => setDailyActivityShow(true)}> <AddIcon /> Add</Button> : <span style={{ marginLeft: '80px' }}>&nbsp;</span>
    //             // return false;

    //     }
    // }

    // const onInputChange = (e) => {
    //     setOpportunity({ ...opportunity, [e.target.name]: e.target.value }, []);
    // };

    const handleNotificationClick = (event) => {
        history.push("/notifications");
    }

    const handleLogout = (event) => {
        setAnchorEl(null);
        window.localStorage.clear();
        window.location.reload();
    }

    
    return (
        <>
<div classname="new_navbar">
  <nav className="navbar navbar-expand-lg navbar-dark navbar_main_bg_color">
    <div className="container-fluid">
      <a className="navbar-brand" href="#"><span style={{ 'fontSize': '22px', 'marginLeft': '5px', 'color': '#fff', 'fontWeight': 'bold' }}>KForce</span></a>
      {/* {renderSwitch(urlPath)} */}
      <>
    
      <Button className="btn-sm navbarAddBtn btn_add_common" onClick={() => setShowRaiseTicketModell(true)} >
           Raise Ticket               
       </Button>
       {showRaiseTicketModel ? <RaiseTicket refreshRaiseTicket = {() => refreshTicketList()} show={showRaiseTicketModel} onHide={() => setShowRaiseTicketModell(false)} /> : ""}
       {/* <RaiseTicket show={showRaiseTicketModel} onHide={() => setShowRaiseTicketModell(false)} /> */}

</>
      <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon" />
      </button>
      <div className="collapse navbar-collapse" id="navbarSupportedContent">
        <ul className="navbar-nav me-auto mb-2 mb-lg-0">
          <li className="nav-item">
            {/* <a className="nav-link active" aria-current="page" href="#">Home</a> */}
            <Link style={{ textDecoration: "none" }} className={urlPath === "home" ? "navbar-links mar-left-100 navbar-active" : "navbar-links mar-left-100"} to="/"> Home
             </Link>
          </li>
          <li className="nav-item">
          <Link style={{ textDecoration: "none" }} className={urlPath === "tickets" ? "navbar-links mar-left-100 navbar-active" : "navbar-links mar-left-100"} to="/ticket-list"> Tickets
             </Link>
            {/* <a className="nav-link" href="#">Link</a> */}
          </li>
          <li className="nav-item">
    
          </li>
          <li className="nav-item">
  
          </li>
          <li className="nav-item">
     
                {/* <Link style={{ textDecoration: "none" }} className={urlPath === "leads" ? "navbar-links navbar-active" : "navbar-links"} to="/leads-list">Leads
                    </Link> */}
              </li>
              <li className="nav-item">
           
              </li>
              <li className="nav-item">
              
      
                           {/* {permissions.includes("viewContactsPage") ? <Link style={{ textDecoration: "none" }} className={urlPath === "tickets" ? "navbar-links navbar-active" : "navbar-links"} to="/tickets-list">Tickets
                    </Link> : false} */}
              </li>
        </ul>
        <div className="topbarLeft d-flex flex-row justify-content-end align-items-center">
        <Link to="/profile"><img src={userImg} className="topbarImg menu-icon" alt="profile" /></Link>   
                    &nbsp;&nbsp;&nbsp;&nbsp;               
                    <span title="Logout" className=" menu-icon logout-icon d-flex flex-row justify-content-start align-items-center" alt="Logout" onClick={handleLogout}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-box-arrow-right" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M10 12.5a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v2a.5.5 0 0 0 1 0v-2A1.5 1.5 0 0 0 9.5 2h-8A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-2a.5.5 0 0 0-1 0v2z" />
                            <path fill-rule="evenodd" d="M15.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L14.293 7.5H5.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3z" />
                        </svg>
                    </span>
                    {/* <Link to="/profile"><img src={userData.profile_pic} className="topbarImg menu-icon" alt="profile" /></Link> */}
          
                </div>
      </div>
    </div>
  </nav>
</div>

        </>

    );
    // }
}

// export default Navbar;