import React, { useState, useEffect, useRef, useContext } from "react";
import axios from 'axios';
import createPlotlyComponent from 'react-plotlyjs';
import Plotly from 'plotly.js/dist/plotly-cartesian';
import Plot from 'react-plotly.js';
//Auth Context
import { AuthContext } from '../../context/AuthContext';


const PlotlyComponent = createPlotlyComponent(Plotly);

const TicketActivityChart = (props) => {

    var nf = new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 3 })

    const { user } = useContext(AuthContext);

    const userData = user.user;
    
    const layout = {

        paper_bgcolor: '#fff ',
        font: {
            size: 12,
            color: '#6E8898'
        },
        margin: {
            l: 120,
            r: 120,
            b: 120,
            t: 120,
           
          },
        // yaxis: {
        //     tickformat: 'cr',
        //     ticksuffix: 'cr'
        // },
        title: "Tickets Activity",
        useResizeHandler: true,
        style: { width: "100%", height: "100%" },
        showlegend: true,
        legend: { "orientation": "h" },
    }

    const config = {
        responsive: true,
        displaylogo: false,
        showTips: true,
        pan2d: true,
        modeBarButtonsToRemove: ['sendDataToCloud', 'hoverClosestPie', 'pan2d', 'select2d', 'lasso2d', 'hoverClosestCartesian', 'hoverCompareCartesian']
    }
    return (
        <>
            <PlotlyComponent
                        data={props.data}
                        layout={layout}
                        useResizeHandler
                        className="w-full h-full"
                        config={config}
                        // id="preSalesComponent"
                    />
        </>
    )
}

export default TicketActivityChart;