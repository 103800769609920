import { useContext, useRef } from 'react';
import { ResetPasswords } from '../../../apiCalls'
import { AuthContext } from '../../../context/AuthContext';
// import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import "./resetPassword.css";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import { Link } from 'react-router-dom';

export default function ResetPassword() {
  // state = {}

  const email = useRef();
  const forgot_otp = useRef();
  const password = useRef();
  const confirm_password = useRef();

  const { user, isFetching, error, dispatch } = useContext(AuthContext);

  const history = useHistory();
  const location = useLocation();

  const handleClick = (e) => {
    e.preventDefault();
    console.log(isFetching);

    if (password.current.value !== confirm_password.current.value) {
      alert("Password and Confirm passwords do not match");
      return;
    }

    var response = ResetPasswords({
      email: email.current.value,
      forgot_otp: forgot_otp.current.value,
      password: password.current.value,
    }, dispatch).then(function (res) {
      console.log("response is ", res);
      if (res.status === 200) {
        history.push("/login");
      } else {
        alert("error : " + res.response.data);
      }
    });
  }
  console.log(user);

  // render() {
  // const PF = process.env.REACT_APP_PUBLIC_FOLDER;
  console.log(user);
  return (

    <div className="login" >
      <div className="loginWrapper">
        {/* <div className="loginLeft">
          <h3 className="loginLogo">Lamasocial</h3>
          <span className="loginDesc">
            Connect with friends and the world around you on Lamasocial.
          </span>
        </div> */}
        <div className="loginRight">
          <span style={{ color: 'white', textAlign: 'center' }}>Note: Do not refresh the page.</span>
          <form className="ResetBox" onSubmit={handleClick}>

            <div className="text-center">
              <h2>Reset Password</h2>
            </div>

            <input placeholder="Email" type="email" required className="loginInput" value={location.data} required ref={email} disabled />

            <input type="number" placeholder="OTP" className="loginInput" required ref={forgot_otp} />

            <input type="password" placeholder="New Password" name="password" ref={password} className="loginInput" required />

            <input type="password" placeholder="Confirm New Password" name="confirm_password" ref={confirm_password} className="loginInput" required />

            <Link to="/login">
              <a className="forgotPassword">Login</a>
            </Link>
            <button className="loginButton" type="submit" disabled={isFetching}>
              {isFetching ? (

                <CircularProgress />
              ) : (
                "Reset Password"
              )}
            </button>

            {/* <button className="loginRegisterButton">
              {isFetching ? (
                <CircularProgress color="white" size="20px" />
              ) : (
                "Create a New Account"
              )}
            </button> */}
          </form>
        </div>
      </div>
    </div>

  );
  // }
}

// export default Login;