import React, { useState, useRef, useContext, useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap'
import axios from "axios";
import { AuthContext } from '../../context/AuthContext';
import { Link, Redirect, useHistory } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';

// Snackbar notification
import ButtonMaterialUi from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { EditorState, convertToRaw, ContentState} from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
// creatable select
import CreatableSelect from 'react-select/creatable';

// import AddNewClient from "./AddNewClient";

// import { EditorState, convertToRaw, ContentState } from 'draft-js';
// import { Editor } from 'react-draft-wysiwyg';
// import draftToHtml from 'draftjs-to-html';
// import htmlToDraft from 'html-to-draftjs';
// import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

// React hook use form
import { useForm } from "react-hook-form";
import Select from 'react-select';
// import { Editor } from 'react-draft-wysiwyg';
// import { EditorState } from 'draft-js';



const RaiseTicket = (props) => {

    // const { user } = useContext(AuthContext);
    const { user } = useContext(AuthContext);
    const client_idis = user?.client?._id
    const account_idis = user?.client?.account
    console.log("client here", account_idis) 

    // snakbar
    const [snackBarMessage, setSnackBarMessage] = useState("");
    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);

    // Add Contact params 
    const [ContactsShow, setContactsShow] = useState(false);
    const [accountList, setAccountList] = useState([])

    const [applicationList, setApplicationList] = useState([]);
    const [familyList, setFamilyList] = useState([]);
    const [productList, setProductList] = useState([]);
    const [osList, setOsList] = useState([]);
    const [versionList, setVersionList] = useState([]);
    const [areaList, setAreaList] = useState([]);
    const [subAreaList, setSubAreaList] = useState([]);
    const [companiesList, setCompaniesList] = useState();
    const [selectedAccount, setSelecteAccount] = useState("");
    // const [updated, setUpdated] = 

    const [addNewClient, setAddNewClient] = useState("")

    const [selectedFile, setSelectedFile] = useState([]);
    const [isSelected, setIsSelected] = useState(false);
    const [isFilePicked, setIsFilePicked] = useState(false);
    const [disable, setDisable] = React.useState(false);

    // const getDetailsHandle = async(id) => {
    //     try{
    //     console.log("table ticket id", id);
      
    //     const res = await axios.get("/tickets/details/" + id);
    //     const feedbackData = res.data.ticketDetails;
    //     console.log("details here", feedbackData)
    //     // alert("inside")
    //     }catch{
    //         // alert("inside catch")
    //     }
    // }

    //     useEffect(() => {
           
    //         getDetailsHandle(account_idis)
    //     },[account_idis])
    // const html = `<p>Clear this text and copy paste your mail body here...😀
    // <br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
    // </p>`;
    const html = `<p>
    <br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
    </p>`;
    const contentBlock = htmlToDraft(html);
    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
    const editorStateTe = EditorState.createWithContent(contentState);
    const [editorState, setEditorState] = useState(editorStateTe)
    const mailBody = useRef();

    const [subArea, setSubArea] = useState("");
    const [Area, setArea] = useState("");
    const [clientsList, setClientsList] = useState([])

    const [activity, setActivity] = useState({
        account: '',
        // first_name:'',
        // last_name:'',
        family: '',
        product_id: '',
        area: '',
        subarea: '',
        product_version: '',
        platform: '',
        os: '',
        summary: '',
        description: '',
        files: ''
    })
    const { register, handleSubmit, formState: { errors: errors }, setError: setActivityError, reset: reset } = useForm();
    //     const  getApplicationList = () => {
    //         setApplicationList([{ label: "Q1", value: 'Q1' },{ label: "Q2", value: 'Q2' }])
    //   }
    const getProductFamilyList = async () => {
        const res = await axios.get("/product-family/search");
        if (res.data.status === "success") {
            setFamilyList(res.data.productFamilyList);
           
        }
    }

    const getClientsList = async() => {
        const res = await axios.post("/client/auth/list", {account_id: selectedAccount});
        if (res.data.status === "success") {
            setClientsList(res.data.clientsList);
         
        }
        // setClientsList
    }

    const getApplicationList = async (value) => {
        const res = await axios.get("/products/list/" + value);
        if (res.data.status === "success") {
            setApplicationList(res.data.productsList);
        
        }
    }

    const getPlatformList = async () => {
        const res = await axios.get("/platform-processor/search");
        if (res.data.status === "success") {
            setProductList(res.data.platformList);
  
        }
    }

    const getOperatingSystemsList = async () => {
        const res = await axios.get("/operating-systems/search");
        if (res.data.status === "success") {
            setOsList(res.data.operatingSystemsList);
         
        }
    }

    const getProductVersionList = async (value) => {
        const res = await axios.get("/product-versions/search/" + value);
        if (res.data.status === "success") {
            setVersionList(res.data.productVersionsList);
     
        }
    }

    const getAreasList = async (value) => {
        const res = await axios.get("/product-areas/search/" + value);
        if (res.data.status === "success") {
            setAreaList(res.data.productAreasList);
      
        }
    }

    const getSubAreasList = async (value) => {
        const res = await axios.get("/product-areas/search/" + value);
        if (res.data.status === "success") {
            setSubAreaList(res.data.productSubAreasList);
          
        }
    }

    useEffect(() => {
        getProductFamilyList();
        getPlatformList();
        getOperatingSystemsList();
    }, [])

    useEffect(() => {
        if(selectedAccount !== ""){
            getClientsList();
        }
    }, [selectedAccount])

    const handleClose = (event, reason) => {
        setAnchorEl(false);
        console.log("handle close");
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };
    const handleClick = () => {
        setOpen(true);
    };

    const addRaiseTicketHandle = async (e) => {

        // alert(mailBody.current.value)


        // console.log("mail body", mailBody.current.value);


// console.log('editor datadddd', editorState.getCurrentContent())
        // if(editorState.length < 50){
        //     console.log("eidtorstate", editorState)
        //     alert("mail body should be atleast 50 characters")
        // }

        if (!Area) {
            setSnackBarMessage("Area is required ...!");
            setOpen(true);
            return;
        }

        if (!subArea) {
            setSnackBarMessage("Sub Area is required ...!");
            setOpen(true);
            return;
        }

        // e.preventDefault();
        setSnackBarMessage("Uploading Data ...!");
        setOpen(true);
        setDisable(true);
        setTimeout(() => { }, 500);

        const formData = new FormData();

        // const activityData= {
        //     // first one will schema feilds

        //     account_id:selectedAccount,
        //     // first_name:user.client.first_name,
        //     // last_name:user.client.last_name,
        //     product_family_id:activity.family,
        //     product_id:activity.product_id,
        //     area_id:activity.area, 
        //     sub_area_id:activity.subarea,
        //     product_version:activity.product_version,
        //     platform_id:activity.platform,
        //     operating_system_id:activity.platform,
        //     summary:activity.summary,
        //     description:activity.description,
        //     // files:formData,
        // }



        formData.append('account_id', account_idis);
        formData.append('product_family_id', activity.family);
        formData.append('product_id', activity.product_id);
        formData.append('area_id', Area);
        formData.append('sub_area_id', subArea);
        formData.append('product_version', activity.product_version);
        formData.append('platform_id', activity.platform);
        formData.append('operating_system_id', activity.os);
        formData.append('summary', activity.summary);
        formData.append('description', activity.description);
        formData.append('client_id', client_idis);
        formData.append('mail_body', mailBody.current.value)

        if (selectedFile.length > 0) {
            for (let i = 0; i < selectedFile.length; i++) {
                formData.append('files', selectedFile[i]);
            }
        }

        formData.mail_body = mailBody.current.value

        // console.log("form data response", formData)
        // return;

        try {

            const res = await axios.post("/tickets/create", formData);
            if (res.data.status === "success") {
                setTimeout(() => {
                    setOpen(true);
                    setSnackBarMessage("Ticket Successfully Added...!");
                    props.onHide();
                }, 2000);
          
                props.refreshRaiseTicket();
                setSnackBarMessage("Ticket Successfully Added...!");
                setContactsShow(false);
                handleClick();

            } else {
                setSnackBarMessage(res.data.msg);
                setContactsShow(true);
                setDisable(false);
            }
            reset({
                keepErrors: false,
                keepDirty: true,
                keepIsSubmitted: false,
                keepTouched: false,
                keepIsValid: false,
                keepSubmitCount: false,
            })
            setDisable(true);
        } catch (err) {
            setDisable(false);
            setOpen(true);
            setSnackBarMessage(err?.response?.data?.msg);

            if (err.response.status === 400) {
                console.log("inside console log")
                // setActivityError("co_" + err.response.data.key, {
                //     type: "manual",
                //     message: err.response.data.message,
                // });                 
            }
            console.log(err);
        }
    }

    const onInputChange = (e) => {
        setActivity({ ...activity, [e.target.name]: e.target.value }, []);
    }

    const familyChangeInput = (e) => {
        setActivity({ ...activity, [e.target.name]: e.target.value }, []);
        getApplicationList(e.target.value);
    }

    const handleChangeSubArea = (value) => {
        setSubArea(value.value);
        // setActivity({...activity, [e.target.name] : e.target.value}, []);
    }

    const handleChangeArea = (value) => {
        setArea(value.value);
    }

    const onInputChangeApplication = (e) => {
        console.log("application value", e.target.value);
        setActivity({ ...activity, [e.target.name]: e.target.value }, []);
        getAreasList(e.target.value);
        getSubAreasList(e.target.value);
        getProductVersionList(e.target.value)
    }

    const colourStyles = {
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            // const color = chroma(data.color);
            console.log({ data, isDisabled, isFocused, isSelected });

            return {
                ...styles,
                // background-color: transparent;
                backgroundColor: isFocused ? "#000" : '#000080',
                color: "white",
                opacity: '1.5'
            };
        }
    };

    const getAccountsList = async (value) => {
        console.log("searching key is ", value);
        setCompaniesList([]);
        const res = await axios.get("/tickets/accounts/search/" + value.target.value);
        setCompaniesList(res.data.accountsList);
        console.log("res account_list here", res);
        // setExistingCompany(false);
        console.error("entire object is ");
        // console.log(activity);
    }

    const handleSetCompanyName = (value) => {
        setSelecteAccount(value.value)
        

        // setCompaniesList({ ...companiesList, [e.target.name]: e.target.value }, []);
    }


    const changeHandler = (event) => {
        setSelectedFile(event.target.files);
        // setSelectedFile({file : event.target.files});
        setIsSelected(true);
    };

    // const handleSubmission = () => {
    //     const formData = new FormData();

    //     formData.append('File', selectedFile);

    //     fetch(
    //         'https://freeimage.host/api/1/upload?key=<YOUR_API_KEY>',
    //         {
    //             method: 'POST',
    //             body: formData,
    //         }
    //     )
    //         .then((response) => response.json())
    //         .then((result) => {
    //             console.log('Success:', result);
    //         })
    //         .catch((error) => {
    //             console.error('Error:', error);
    //         });
    // };


    const onEditorStateChange = (data) => {
        console.log("editor data", data)
        setEditorState(data)
    }

    return (
        <>
            {/* Add Contacts Modal */}
            < Modal
                show={props.show}
                onHide={() => setContactsShow(false)}
                backdrop={false}
                disableBackdrop={true}
                dialogClassName="modal-90w"
                arialabelledby="example-custom-modal-styling-title">
                <Modal.Header >
                    <Modal.Title id="example-custom-modal-styling-title">
                        Create New Ticket
                    </Modal.Title>
                    <a onClick={props.onHide} className="btn btn-sm btn-outline-danger bottomBarButtons">Cancel</a>
                </Modal.Header>
                {/* onSubmit={handleSubmit(addActivityHandle)} */}
                <Modal.Body>
                    <div className="from">
                        <form key={1} onSubmit={handleSubmit(addRaiseTicketHandle)} >
                            <div className="row">
                                {/* <div className="col-md-6">
                                    <Select
                                      isDisabled={true}
                                        closeMenuOnSelect={true}
                                        className="basic-single"
                                        classNamePrefix="select"
                                        isSearchable="true"
                                        options={companiesList}
                                        placeholder="Account"
                                        styles={colourStyles}
                                        
                                        onChange={handleSetCompanyName}
                                        // onChange = {(value) => setSelecteAccount(value.value)}
                                        onKeyDown={getAccountsList}
                                    // onNewOptionClick={onNewOptionClick}
                                    ></Select>

                                </div> */}

                                <div className="col-md-6">
                                    <TextField variant="outlined"
                                        {...register("family", {
                                            required: {
                                                value: true,
                                                message: "family is required"
                                            },

                                        })}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        // style={{
                                        //     borderLeftWidth: 3,
                                        //     borderLeftColor: 'red',
                                        //     borderLeftStyle: 'solid'
                                        // }}
                                        value={activity.family}
                                        select
                                        helperText={errors.family ? errors.family.message : ''}
                                        error={Boolean(errors.family)}
                                        onChange={e => familyChangeInput(e)}
                                        required
                                        type="text" className="form-control" name="family" label="Family"
                                    >
                                        {familyList.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))
                                        }
                                    </TextField>

                                </div>
                                <div className="col-md-6">

                                    <TextField variant="outlined"
                                        {...register("product_id", {
                                            required: {
                                                value: true,
                                                message: "product id is required"
                                            },
                                            // minLength: {
                                            //     value: 2,
                                            //     message: "Your name must be at least 2 characters"
                                            // }
                                        })}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        // style={{
                                        //     borderLeftWidth: 3,
                                        //     borderLeftColor: 'red',
                                        //     borderLeftStyle: 'solid'
                                        // }}
                                        value={activity.product_id}
                                        select
                                        helperText={errors.product_id ? errors.product_id.message : ''}
                                        error={Boolean(errors.product_id)}
                                        onChange={e => onInputChangeApplication(e)}
                                        required
                                        type="text" className="form-control" name="product_id" label="Application"
                                    >
                                        {applicationList.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))
                                        }
                                    </TextField>


                                    </div>
                            </div>
                            <br />

                            <div className="row">

                          
                                <div className="col-md-6">
                                    <CreatableSelect
                                        closeMenuOnSelect={true}
                                        className="basic-single"
                                        classNamePrefix="select"
                                        isSearchable="true"
                                        options={areaList}
                                        name="subarea"
                                        placeholder="Search Area Name"
                                        // styles={colourStyles}
                                        onChange={handleChangeArea}
                                    // onNewOptionClick={onNewOptionClick}
                                    >
                                    </CreatableSelect>

                                    {/* <TextField variant="outlined"
                                        {...register("area", {
                                            // required: { 
                                            //     value: true, 
                                            //     message: "area is required" 
                                            // },
                                            // minLength: {
                                            //     value: 2,
                                            //     message: "Your name must be at least 2 characters"
                                            // }
                                        })}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        // style={{
                                        //     borderLeftWidth: 3,
                                        //     borderLeftColor: 'red',
                                        //     borderLeftStyle: 'solid'
                                        // }}
                                        value={activity.area}
                                        select
                                        helperText={errors.area ? errors.area.message : ''}
                                        error={Boolean(errors.area)}
                                        onChange={e => onInputChange(e)}
                                        // required
                                        type="text" className="form-control"  name="area" label="Area"
                                    >
                                        {areaList.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))
                                        }
                                    </TextField> */}


                                </div>
                                <div className="col-md-6">

                                <CreatableSelect
                                    closeMenuOnSelect={true}
                                    className="basic-single"
                                    classNamePrefix="select"
                                    isSearchable="true"
                                    options={subAreaList}
                                    name="subarea"
                                    placeholder="Search Sub Area Name"
                                    // styles={colourStyles}
                                    onChange={handleChangeSubArea}
                                // onNewOptionClick={onNewOptionClick}
                                >
                                </CreatableSelect>

                                {/* <TextField variant="outlined"
                                    {...register("subarea", {
                                        // required: { 
                                        //     value: true, 
                                        //     message: "subarea is required" 
                                        // },
                                        // minLength: {
                                        //     value: 2,
                                        //     message: "Your name must be at least 2 characters"
                                        // }
                                    })}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    // style={{
                                    //     borderLeftWidth: 3,
                                    //     borderLeftColor: 'red',
                                    //     borderLeftStyle: 'solid'
                                    // }}
                                    value={activity.subarea}
                                    select
                                    helperText={errors.subarea ? errors.subarea.message : ''}
                                    error={Boolean(errors.subarea)}
                                    onChange={e => onInputChange(e)}
                                    // required
                                    type="text" className="form-control"  name="subarea" label="SubArea"
                                >
                                    {subAreaList.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))
                                    }
                                </TextField> */}
                                </div>
                            </div>
                            <br />
                            <div className="row">

                               
                                <div className="col-md-6">
                                    <TextField variant="outlined"
                                        {...register("product_version", {
                                            // required: { 
                                            //     value: true, 
                                            //     message: "product version is required" 
                                            // },
                                            // minLength: {
                                            //     value: 2,
                                            //     message: "Your name must be at least 2 characters"
                                            // }
                                        })}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        // style={{
                                        //     borderLeftWidth: 3,
                                        //     borderLeftColor: 'red',
                                        //     borderLeftStyle: 'solid'
                                        // }}
                                        value={activity.product_version}
                                        select
                                        helperText={errors.product_version ? errors.product_version.message : ''}
                                        error={Boolean(errors.product_version)}
                                        onChange={e => onInputChange(e)}
                                        // required
                                        type="text" className="form-control" name="product_version" label="Product Version">
                                        {versionList.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))
                                        }
                                    </TextField>
                                </div>
                                <div className="col-md-6">
                                    <TextField variant="outlined"
                                        {...register("platform", {
                                            required: {
                                                value: true,
                                                message: "platform is required"
                                            },
                                            // minLength: {
                                            //     value: 2,
                                            //     message: "Your name must be at least 2 characters"
                                            // }
                                        })}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        // style={{
                                        //     borderLeftWidth: 3,
                                        //     borderLeftColor: 'red',
                                        //     borderLeftStyle: 'solid'
                                        // }}
                                        value={activity.platform}
                                        select
                                        helperText={errors.platform ? errors.platform.message : ''}
                                        error={Boolean(errors.platform)}
                                        onChange={e => onInputChange(e)}
                                        required
                                        type="text" className="form-control" name="platform" label="Platform"
                                    >
                                        {productList.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))
                                        }
                                    </TextField>
                                </div>
                            </div>
                            <br />
                            <div className="row">

                              
                                <div className="col-md-6">
                                    <TextField variant="outlined"
                                        {...register("os", {
                                            required: {
                                                value: true,
                                                message: "OS version is required"
                                            },
                                            // minLength: {
                                            //     value: 2,
                                            //     message: "Your name must be at least 2 characters"
                                            // }
                                        })}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        // style={{
                                        //     borderLeftWidth: 3,
                                        //     borderLeftColor: 'red',
                                        //     borderLeftStyle: 'solid'
                                        // }}
                                        value={activity.os}
                                        select
                                        helperText={errors.os ? errors.os.message : ''}
                                        error={Boolean(errors.os)}
                                        onChange={e => onInputChange(e)}
                                        required
                                        type="text" className="form-control" name="os" label="OS"
                                    >
                                        {osList.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))
                                        }
                                    </TextField>


                                </div>
                            </div>
                            <br />

                      

                            <div className='row'>
                                <div className="col-md-6">
                                    <TextField multiline rows={2}
                                        variant="outlined"
                                        {...register("summary", {
                                            //     required: { 
                                            //         value: true, 
                                            //         message: "Designation is required" 
                                            //     },
                                            minLength: {
                                                value: 5,
                                                message: "summary must be at least 5 characters"
                                            }
                                        })}
                                        required
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        // style={{
                                        //     borderLeftWidth: 3,
                                        //     borderLeftColor: 'red',
                                        //     borderLeftStyle: 'solid'
                                        // }}
                                        onChange={e => onInputChange(e)}
                                        value={activity.summary}
                                        helperText={errors.summary ? errors.summary.message : ''}
                                        error={Boolean(errors.summary)}
                                        // inputRef={designation}
                                        type="text" className="form-control" id="summary" name="summary" label="Title" />

                                </div>
                                {/* <div className="col-md-6">
                                    <TextField multiline rows={2}
                                        variant="outlined"
                                        {...register("description", {
                                            //     required: { 
                                            //         value: true, 
                                            //         message: "Designation is required" 
                                            //     },
                                            minLength: {
                                                value: 10,
                                                message: "description must be at least 10 characters"
                                            }
                                        })}
                                        required
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        // style={{
                                        //     borderLeftWidth: 3,
                                        //     borderLeftColor: 'red',
                                        //     borderLeftStyle: 'solid'
                                        // }}
                                        onChange={e => onInputChange(e)}
                                        value={activity.description}
                                        helperText={errors.description ? errors.description.message : ''}
                                        error={Boolean(errors.description)}
                                        // inputRef={designation}
                                        type="text" className="form-control" id="description" name="description" label="Description" />
                                </div> */}

                                <br />
                                <br />
                                <div className="form-group col-md-12 editor" style={{marginTop: '30px'}} >
                                    <label className="font-weight-bold"> Mail Body <span className="required"> * </span> </label>
                                    <div style={{minHeight:'350px',border:'1px grey solid'}}>
                                    
                                    <Editor
                                        editorState={editorState}
                                        toolbarClassName="toolbarClassName"
                                        wrapperClassName="wrapperClassName"
                                        editorClassName="editorClassName"
                                        // onEditorStateChange={onEditorStateChange}
                                        onEditorStateChange = {onEditorStateChange}
                                        />
                                    </div>
                                    <textarea
                                     style={{display:'none'}}
                                    disabled
                                    ref={mailBody}
                                    value={draftToHtml(convertToRaw(editorState.getCurrentContent()))}
                                    />
                                    {/* <textarea style={{display:'none'}} disabled ref={(val) => userInfo.description = val} value={draftToHtml(convertToRaw(editorState.getCurrentContent())) } /> */}
                                </div>

                                <div className="col-md-6">
                                    <br />
                                    <div>

                                        <input type="file" className='custom-file-input' id="file" name="file" multiple onChange={changeHandler} />

                                        {/* <label htmlFor="filePicker" style={{ background:"#16a085", padding:"5px 10px", borderRadius:"4px", color:'#fff',cursor:'pointer' }}>
        Attachment
            </label>
            <input type="file" style={{visibility:"hidden"}} id="filePicker"  name="file" multiple  onChange={changeHandler} /> */}

                                        {/* {isSelected ? (
				<div>
             
					<p>Filename: {selectedFile.name}</p>
					<p>Filetype: {selectedFile.type}</p>
					<p>Size in bytes: {selectedFile.size}</p>
				
				</div>
			) : (
				<p>Select a file to show details</p>
			)} */}

                                    </div>
                                </div>

                                

                                <div className="col-md-6">
                                    <br />
                                    <button disabled={disable} className="btn btn-sm btn-primary btn_submit_common_green  bottomBarButtons" id="submit">Submit</button>&nbsp;&nbsp;
                                </div>
                            </div>

                            {/* <br />
                        <div className="row">
                            <div >
                                <button className="btn btn-sm btn-primary btn_submit_common_green  bottomBarButtons" id="submit">Submit</button>&nbsp;&nbsp;
                            </div>
                        </div> */}
                        </form>
                    </div>
                </Modal.Body>
            </Modal >

            {/* {addNewClient ? <AddNewClient account_id={selectedAccount}  refreshContactslist={() => getClientsList()} show={addNewClient} onHide={() => setAddNewClient(false)} /> : ""} */}
            {/* Snack Bar */}
            <Snackbar
                severity="success"

                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={open}
                autoHideDuration={2000}
                onClose={handleClose}
                message={snackBarMessage}
                action={
                    <React.Fragment>
                        <ButtonMaterialUi severity="success" color="success" size="small" onClick={handleClose}>
                        </ButtonMaterialUi>
                        <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    </React.Fragment>
                }
            />
        </>
    );
}
export default RaiseTicket;

