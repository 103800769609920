import React, { useState } from 'react';
import { Modal } from 'react-bootstrap'
import axios from "axios";
import TextField from '@material-ui/core/TextField';


// Snackbar notification
import ButtonMaterialUi from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

// React hook use form
import { useForm } from "react-hook-form";
const ClosedTicket = (props) => {
    console.log("close feedback details................", props.feedback);
// snakbar
    const [snackBarMessage, setSnackBarMessage] = useState("");
    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    
    // Add Contact params 
    const [ContactsShow, setContactsShow] = useState(false);
    const [activity, setActivity] = useState({
    ticket_id: props.feedback ? props.feedback.ticket_id : null,
    feedback:''
})
    const { register, handleSubmit, formState: { errors: errors }, setError: setActivityError, reset: reset } = useForm();


    const handleClose = (event, reason) => {
        setAnchorEl(false);
        console.log("handle close");
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };
    const handleClick = () => {
        setOpen(true);
      };
const addActivityTicket = async (e) => {
    // e.preventDefault();
   setSnackBarMessage("Uploading Data ...!");
        setOpen(true);

        setTimeout(() => { }, 500);

    const activityData= {
        // first one will schema feilds

        ticket_id:activity.ticket_id,
        feedback:activity.feedback
    }
     console.log("CLOSE data response here.........", activityData)
    try {
        const res = await axios.post("/tickets/close-ticket", activityData);
        if (res.data.status === "success") {
        //  console.log("ticket details", res)
        setTimeout(() => {
            setOpen(true);
            setSnackBarMessage("Ticket Successfully Closed...!");
            props.onHide();
          }, 2000);
    
          setSnackBarMessage("Ticket Successfully Closed...!");
          setContactsShow(false);
          handleClick();
         props.refreshCloseTicket(activity.ticket_id)
       } else {
        setSnackBarMessage("Error in adding ticket...!");
        setContactsShow(true);
       }
            reset({
                keepErrors: false,
                keepDirty: true,
                keepIsSubmitted: false,
                keepTouched: false,
                keepIsValid: false,
                keepSubmitCount: false,
            })
    // props.onHide();

    }catch (err) {

        if (err.response.status === 400) {
            console.log("inside console log")
            // setActivityError("co_" + err.response.data.key, {
            //     type: "manual",
            //     message: err.response.data.message,
            // });                 
        }
        console.log(err);
    }
}

const onInputChange = (e) => {
    setActivity({ ...activity, [e.target.name]: e.target.value }, []);
}

    return (
        <>
            {/* Add Contacts Modal */}
            < Modal
                show={props.show}
                onHide={() => setContactsShow(false)}
                backdrop={false}
                disableBackdrop={true}
                dialogClassName="modal-90w"
                arialabelledby="example-custom-modal-styling-title">
                <Modal.Header >
                    <Modal.Title id="example-custom-modal-styling-title">
                        Close Ticket
                    </Modal.Title>
                    <a onClick={props.onHide} className="btn btn-sm btn-outline-danger bottomBarButtons">Cancel</a>
                </Modal.Header>
                {/* onSubmit={handleSubmit(addActivityHandle)} */}
                <Modal.Body>
         <div className="from">
         <form key={1} onSubmit={handleSubmit(addActivityTicket)} >
                        <div className='row'>
                        <div className="col-md-6">
                                <TextField multiline rows={4}
                                   variant="outlined"
                                    {...register("feedback", {
                                        //     required: { 
                                        //         value: true, 
                                        //         message: "Designation is required" 
                                        //     },
                                        minLength: {
                                            value: 10,
                                            message: "feedback must be at least 10 characters"
                                        }
                                    })}
                                    required
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    // style={{
                                    //     borderLeftWidth: 3,
                                    //     borderLeftColor: 'red',
                                    //     borderLeftStyle: 'solid'
                                    // }}
                                    onChange={e => onInputChange(e)}
                                    value={activity.feedback}
                                    helperText={errors.feedback ? errors.feedback.message : ''}
                                    error={Boolean(errors.feedback)}
                                    // inputRef={designation}
                                    type="text" className="form-control" id="feedback" name="feedback" label="Feedback" />
                            </div>
                        </div>
                        <div className="row">
                            <div >
                                <button className="btn btn-sm btn-primary btn_submit_common_green  bottomBarButtons" id="submit"  >Submit</button>&nbsp;&nbsp;
                            </div>
                        </div>
                       </form>
                  </div>
                </Modal.Body>
            </Modal >
            {/* Snack Bar */}
            <Snackbar
                severity="success"

                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={open}
                autoHideDuration={2000}
                onClose={handleClose}
                message={snackBarMessage}
                action={
                    <React.Fragment>
                        <ButtonMaterialUi severity="success" color="success" size="small" onClick={handleClose}>
                        </ButtonMaterialUi>
                        <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    </React.Fragment>
                }
            />
        </>
    );
}
export default ClosedTicket;

