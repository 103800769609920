import React, { useState, useContext } from 'react';
import axios from "axios";

// Material Items
import TextField from '@material-ui/core/TextField';

// Snackbar notification
import MaterialUiButton from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Snackbar from '@material-ui/core/Snackbar';
import { useHistory } from "react-router";

// React hook use form
import { useForm } from "react-hook-form";

//Context 
// import { AuthContext } from '../../../context/AuthContext';
import { AuthContext } from '../../../context/AuthContext';

// Navbar 
import Navbar from '../../home/Navbar';
import './profile.css';
import userImg from "../../../images/user2.jpeg"
export default function Profile() {

    // const { user } = useContext(AuthContext).user.client;

  const { user } = useContext(AuthContext);
  const userData = user.client;

//   console.log("user details here......", userData.first_name)

    const [snackBarMessage, setSnackBarMessage] = useState(false);

    const { register, handleSubmit, formState: { errors: errors }, reset: reset } = useForm();

    const [open, setOpen] = React.useState(false);

    const [profilePic, setProfilePic] = useState();

    const [profilePicName, setProfilePicName] = useState("");

    const [profilePicImageUrl, setProfilePicImageUrl] = useState("");


    const handleClick = () => {
        setOpen(true);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    const [profile, setProfile] = useState({
        first_name: userData.first_name ,
        last_name: userData.last_name ,
        email: userData.email,
        phone: userData.phone,
        job_title: userData.job_title,
        password: "",
        confirm_password: "",
        company:userData.account
    });

    const history = useHistory("");

    const registrationError = false

    const onInputChange = (e) => {
        setProfile({ ...profile, [e.target.name]: e.target.value }, []);
    };

    const profileUpdate = async (e) => {
        console.log("profile ");
        console.log(profile);

        const updateData = {
            first_name: profile.first_name,
            last_name: profile.last_name,
            email: profile.email,
            phone: profile.phone,
            job_title: profile.job_title,
            password: profile.password,
            // company: profile.company,
            client_id: userData._id,
            profile_pic: profilePic,
        }
        console.log("profile data here", updateData)
        if (profile.password !== profile.confirm_password) {
            alert("Passwords din't match");
            // confirm_password.current.setCustomValidity("Passwords dint match!");
        } else {
            try {
                await axios.post("/client/auth/profile/update", updateData, {
                    headers: {
                        "content-type": "application/json"
                    }
                });
                // history.push("/login");
                setSnackBarMessage("Profile Updated Successfully !");
                handleClick();
            } catch (err) {
                // registrationError = err.response;
                console.log(err);
            }
        }
    }

    // const fetchUserDetails = async () => {
    //     const res = await axios.get("/user/profile/details/" + user);
    //     const profileDetails = res.data.user;
    //     setProfile({
    //         first_name: profileDetails.first_name,
    //         last_name: profileDetails.last_name,
    //         email: profileDetails.email,
    //         phone: profileDetails.phone,
    //         job_title: profileDetails.job_title,
    //         company: profileDetails.company,
    //     })
    //     setProfilePicImageUrl(profileDetails.profile_pic);
    //     console.log(res);
    // }

    // useEffect(() => {
    //     fetchUserDetails();
    // }, []);


    const imageHandler = async (e) => {
        // const reader = new FileReader();

        setProfilePic(e.target.files[0]);
        setProfilePicName(e.target.files[0].name);

        console.log(e.target.files[0]);
        console.log("target file", e.target.files[0]);
        const formData = new FormData();
        formData.append("file", e.target.files[0]);

        formData.append("fileName", e.target.files[0].name);
        console.log("next below is all the response");
        formData.append("client_id", userData._id);

        try {
            const res = await axios.post(
                "/client/auth/profile/image-upload",
                formData
            );
            setProfilePicImageUrl(res.data.profile_pic);
            console.log(res);
        } catch (ex) {
            console.log(ex);
        }



        // reader.onload = () => {
        // if (reader.readyState === 2) {
        //     this.setState({ profileImg: reader.result })
        // }    
        // }
        // reader.readAsDataURL(e.target.files[0])
    };

    const onNewFileSelected = (e) => {
        alert("hello");
        console.log("new file selected");
        console.log(e)
    }

    const onNewFileSelectedTrigger = (e) => {

    }

    return (
        <div>
            <Navbar value="profile" className="activity_list_navbar" />
            <div className="profile">
                <div className="loginWrapper_profile">
                    <div className="loginRight">
                        <form className="registerBox registerBox_profile" onSubmit={handleSubmit(profileUpdate)} >
                            <div className="text-center">
                                {/* <h2>Profile</h2> */}
                                <br />
                                {registrationError}
                            </div>
                            {/* <input name="image-upload" accept="image/*" type="file" id="profileImageSelect" onChange={onNewFileSelected} /> */}
                            <img
                                className="profileUserImg"
                                src={userImg}
                                // src={profilePicImageUrl}
                                alt=""
                                type="file"
                                onClick={onNewFileSelectedTrigger}

                            />
                         
                             <br />
                             <br />
                             <br />
                             <br />
                             <br />
                          
                            {/* <a className="image-upload" className="btn btn-primary" htmlFor="profileImageSelect" >Select Image</a> */}
                            {/* <br />
                            <input type="file" accept="image/*" name="image-upload" id="input" onChange={imageHandler} />
                            <div className="label">
                                <label className="image-upload" htmlFor="input">
                                   
                                    Choose your Photo
                                </label>
                            </div>
                            <br /> */}

                            <TextField
                                variant="outlined"
                                label="First Name"
                                name="first_name"

                                value={profile.first_name}
                                {...register("first_name", {
                                    // required: {
                                    //     value: true,
                                    //     message: "First Name is required"
                                    // },
                                    minLength: {
                                        value: 3,
                                        message: "First name must be at least 3 characters"
                                    }
                                })}
                                onChange={e => onInputChange(e)}
                                helperText={errors.first_name ? errors.first_name.message : ''}
                                error={Boolean(errors.first_name)}

                                className="loginInput"
                            />
                               <br />
                            <TextField variant="outlined"
                                label="Last Name"
                                name="last_name"
                                value={profile.last_name}
                                {...register("last_name", {
                                    // required: {
                                    //     value: true,
                                    //     message: "Last Name is required"
                                    // },
                                    minLength: {
                                        value: 1,
                                        message: "Last name must be at least 1 characters"
                                    }
                                })}
                                onChange={e => onInputChange(e)}
                                helperText={errors.last_name ? errors.last_name.message : ''}
                                error={Boolean(errors.last_name)}

                                className="loginInput"

                            />
                               <br />
                            <TextField variant="outlined"
                                label="Email"
                                name="email"
                                inputProps={{ readOnly: true }}
                                InputLabelProps={{
                                    shrink: true
                                }}
                                isDisabled={true}
                                disabled
                                value={profile.email}
                                {...register("email", {
                                    // required: {
                                    //     value: true,
                                    //     message: "Email is required"
                                    // },
                                    minLength: {
                                        value: 3,
                                        message: "Email must be at least 1 characters"
                                    }
                                })}
                                onChange={e => onInputChange(e)}
                                helperText={errors.email ? errors.email.message : ''}
                                error={Boolean(errors.email)}

                                className="loginInput"
                                type="text"

                            />
                              <br />
                            <TextField variant="outlined"
                                label="Phone"
                                name="phone"
                                InputProps={{
                                    readOnly: true,
                                }}
                                // InputLabelProps={{
                                //     shrink: true
                                // }}
                                disabled
                                value={profile.phone}
                                {...register("phone", {
                                    // required: {
                                    //     value: true,
                                    //     message: "Phone is required"
                                    // },
                                    minLength: {
                                        value: 3,
                                        message: "Phone must be at least 1 characters"
                                    }
                                })}
                                onChange={e => onInputChange(e)}
                                helperText={errors.phone ? errors.phone.message : ''}
                                error={Boolean(errors.phone)}

                                className="loginInput"
                                type="text"
                            />
                               <br />
                            <TextField variant="outlined"
                                label="Job Title"
                                name="job_title"
                                value={profile.job_title}
                                {...register("job_title", {
                                    // required: {
                                    //     value: true,
                                    //     message: "Job Title is required"
                                    // },
                                    minLength: {
                                        value: 3,
                                        message: "Job Title must be at least 1 characters"
                                    }
                                })}
                                onChange={e => onInputChange(e)}
                                helperText={errors.job_title ? errors.job_title.message : ''}
                                error={Boolean(errors.job_title)}


                                className="loginInput"
                                type="text"
                            />
                                <br />
                            <TextField variant="outlined"
                                label="Password"
                                name="password"
                                value={profile.password}
                                {...register("password", {
                                    // required: {
                                    //     value: true,
                                    //     message: "Password is required"
                                    // },
                                    minLength: {
                                        value: 3,
                                        message: "Password must be at least 6 characters"
                                    }
                                })}
                                onChange={e => onInputChange(e)}
                                helperText={errors.password ? errors.password.message : ''}
                                error={Boolean(errors.password)}
                                className="loginInput"
                                type="password"
                            />
                              <br />
                            <TextField variant="outlined"
                                name="confirm_password"
                                label="Confirm Password"

                                value={profile.confirm_password}
                                {...register("confirm_password", {
                                    // required: {
                                    //     value: true,
                                    //     message: "Confirm Password is required"
                                    // },
                                    minLength: {
                                        value: 3,
                                        message: "Confirm Password must be at least 6 characters"
                                    }
                                })}
                                onChange={e => onInputChange(e)}
                                helperText={errors.confirm_password ? errors.confirm_password.message : ''}
                                error={Boolean(errors.confirm_password)}

                                className="loginInput"
                                type="password"
                            />
                                <br />
                            <button className="loginButton" type="submit">
                                Update
                            </button>


                        </form>
                    </div>
                </div>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    open={open}
                    autoHideDuration={2000}
                    onClose={handleClose}
                    message={snackBarMessage}
                    action={
                        <React.Fragment>
                            <MaterialUiButton color="secondary" size="small" onClick={handleClose}>
                            </MaterialUiButton>
                            <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
                                <CloseIcon fontSize="small" />
                            </IconButton>
                        </React.Fragment>
                    }
                />
            </div >
        </div>
    )
}