import { useContext, useRef } from 'react';
import { ForgotPasswords } from '../../../apiCalls'
import { AuthContext } from '../../../context/AuthContext';
// import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import "./forgotPassword.css";
import { useHistory } from "react-router";
import { Link } from 'react-router-dom';
    
export default function ForgotPassword() {
  // state = {}
    
    const email = useRef();
    const { user, isFetching, error, dispatch } = useContext(AuthContext);
    const history = useHistory();

  const handleClick = (e) => {
    e.preventDefault();
    console.log(email.current.value);
    console.log("submit button clicked");
    console.log(email.current.value);

    console.log(isFetching);
        
    var response = ForgotPasswords({ email: email.current.value }, dispatch).then(function (res) {
            console.log("response is ", res);
            if (res.status === 200) {
                history.push({
                    pathname: '/reset-password',
                    data: email.current.value,
                    state: {email: email.current.value}
                });
            } else {
                alert("error : " + res.response.data);
            }
        });; 
  }
  console.log(user);
    
  // render() {
  // const PF = process.env.REACT_APP_PUBLIC_FOLDER;
  console.log(user);
  return (
            
    <div className="login" >
      <div className="loginWrapper12">
        {/* <div className="loginLeft">
          <h3 className="loginLogo">Lamasocial</h3>
          <span className="loginDesc">
            Connect with friends and the world around you on Lamasocial.
          </span>
        </div> */}
        <div className="loginRight">
          <form className="forgotBox" onSubmit={handleClick}>
            <div className="text-center">
              <h2>Forgot Password</h2>
            </div>
            <input placeholder="Email" type="email" required className="loginInput" ref={email} />
            <Link to="/login">
                <a className="forgotPassword">Login</a>
            </Link>
            <button className="loginButton" type="submit" disabled={isFetching}>
              {isFetching ? (
                                   
                    <CircularProgress />
              ) : (
                "Reset Password"
              )}
            </button>
            
            {/* <button className="loginRegisterButton">
              {isFetching ? (
                <CircularProgress color="white" size="20px" />
              ) : (
                "Create a New Account"
              )}
            </button> */}
          </form>
        </div>
      </div>
    </div>
        
        );
    // }
}
 
// export default Login;