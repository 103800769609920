import React, { useContext,useState,useEffect } from "react";
import axios from "axios"
import Navbar from "./Navbar";
import { ImTicket } from 'react-icons/im';
import "./home.css";
import TicketActivityChart from "../../components/dashboard/TicketActivityChart";
//Auth Context
// import { AuthContext } from '../../context/AuthContext';

const Home = (props) => {
  const [data, setData] = useState([]);
  const[totalTickets, setTotalTickest]=useState(null);
const[resolvedTickets, setResolvedTickest]=useState(null);

  const getChartData = async() => {
    const res = await axios.get("/tickets/client-chart")
    if(res.data.status==="success"){
      setData(res.data.data);
        setTotalTickest(res.data.total_tickets);
        setResolvedTickest(res.data.resolved_tickets);
    }
   
}
    useEffect(() => {
    getChartData();
    },[]);

const fechData = () =>{}
    return(
        <div>
        <Navbar value="home" refreshTicketList={() => fechData()}/>
        <>
        <div className="main_dashboard_page">
<div className="container-fluid">
  <div className="row">
<div className="col-lg-11 col-md-11 col-12 mx-auto">
  <div className="row">
    <div className="col-md-8 col-lg-8">
      <div className="ticket_Activity">
      <TicketActivityChart data={data} />
      </div>
    </div>
    <div className="col-md-4 col-lg-4 mt-5 mt-lg-0 d-flex flex-column justify-content-center align-items-center">
    <div className="total_tickets">
        <ImTicket className="ticket_icon"/>
        <h4>Total Number of Tickets</h4>
        <h6>{totalTickets}</h6>
      </div>
      <br />
      <div className="total_tickets1">
    <ImTicket className="ticket_icon"/>
        <h4>Number of Tickets Resolved</h4>
        <h6>{resolvedTickets}</h6>
        </div>
    </div>
  </div>
</div>
  </div>
</div>
</div>

        </>
      </div>
    )
}
export default Home;